<template>
  <div id="app">
    <Menu :isConnected="isConnected" />
    <main id="page-wrap">
      <div class="container">
        <img src="./assets/img/logo.png" alt="Logo" class="head-logo">
        <router-view
          :isConnected="isConnected"
          :editedArticle="editedArticle"
          @login="login"
          @new-article="newArticle"
          @edit-article="editArticle"
          @delete-article="deleteArticle"
          @patch-article="patchArticle"
          @send-email="sendEmail"
        ></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
const axios = require('axios')
axios.defaults.withCredentials = true

export default {
  name: 'App',
  components: {
    Menu
  },
  data() {
    return {
      isConnected: false,
      editedArticle: { type: Object }
    }
  },
  methods: {
    async login(admin) {
      try {
        await axios.post('https://jrg-consulting.fr/api/admin', 'email=' + admin.email + '&password=' + admin.password, { withCredentials: true })
        await this.$router.push('/')
        this.isConnected = true
      } catch (err) {
        return console.error('network error', err)
      }
    },
    async newArticle(article) {
      try {
        if (this.isConnected === true) {
          await axios.post('https://jrg-consulting.fr/api/newsletter', 'title=' + article.title + '&content=' + article.content)
          await this.$router.push('/Newsletter')
        } else {
          return console.error('client error')
        }
      } catch (err) {
        return console.error('network error', err)
      }
    },
    async editArticle(article) {
      try {
        if (this.isConnected === true) {
          await this.$router.push('/Modifier-article')
          this.editedArticle = article
        } else {
          return console.error('client error')
        }
      } catch (err) {
        return console.error('network error', err)
      }
    },
    async patchArticle(article) {
      try {
        if (this.isConnected === true) {
          await axios.patch('https://jrg-consulting.fr/api/newsletter/' + article.id, 'title=' + article.title + '&content=' + article.content)
          await this.$router.push('/Newsletter')
        } else {
          return console.error('client error')
        }
      } catch (err) {
        return console.error('network error', err)
      }
    },
    async deleteArticle(articleId) {
      try {
        if (this.isConnected === true) {
          await axios.delete('https://jrg-consulting.fr/api/newsletter/' + articleId)
        } else {
          return console.error('client error')
        }
      } catch (err) {
        return console.error('network error', err)
      }
    },
    async sendEmail(email) {
      try {
        await axios.post('https://jrg-consulting.fr/api/contact/', 'fullname=' + email.fullname + '&email=' + email.email + '&subject=' + email.subject + '&text=' + email.text)
        await this.$router.push('/')
      } catch (err) {
        return console.error('network error', err)
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
  $darkBlue: #093145;
  font-family: "Roboto", sans-serif;
  background-color: #093145;
  color: #093145;
}

.container {
  width: 1140px;
  min-height: calc(100vh - 20px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fafafa;
  @media screen and (max-width: 768px) {
    width: auto;
    .head-logo {
      width: 50%;
      margin-left: 10px;
    }
  }
}

.head-logo {
  display: flex;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  text-align: center;
  font-weight: 300;
  font-size: x-large;
  margin-top: 20px;
}
</style>
