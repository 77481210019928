<template>
  <ScaleDown :right="true" :closeOnNavigation="true" class="scale-down">
    <router-link to="/" class="underline-link">
      <v-icon name="regular/building" />
      <span class="accueil-space">Accueil</span>
    </router-link>
    <router-link to="/Newsletter" class="underline-link">
      <v-icon name="regular/newspaper" />
      <span class="newsletter-space">Newsletter</span>
    </router-link>
    <router-link to="/Contact" class="underline-link">
      <v-icon name="regular/envelope" />
      <span class="contact-space">Contact</span>
    </router-link>
    <a href="https://www.linkedin.com/in/jean-roch-guillemin-rrh/" target="_blank" class="underline-link">
      <v-icon name="brands/linkedin-in"/>
      <span class="linkedin-space">LinkedIn</span>
    </a>
    <router-link to="/Nouvel-article" class="underline-link" v-show="isConnected">
      <v-icon name="regular/edit" />
      <span class="newArticle-space">Écrire</span>
    </router-link>
  </ScaleDown>
</template>

<script>
import { ScaleDown } from 'vue-burger-menu'

export default {
  components: {
    ScaleDown
  },
  props: {
    isConnected: { type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
.scale-down {
  & >>> .bm-menu {
    background-color: #fafafa;
    transition: 0.7s;
  }

  & >>> .bm-burger-bars {
    background-color: #fafafa;
    @media screen and (max-width: 1240px) {
      background-color: #093145;
    }
  }

  & >>> .bm-burger-button {
    height: 25px;
    width: 30px;
  }

  & >>> .bm-overlay > a {
    background-color: #093145;
  }

  & >>> .bm-item-list > * {
    color: #093145;
    line-height: 1;
    & > span {
      color: #093145;
      font-weight: 400;
    }
  }

  & >>> .bm-cross {
    background: #093145;
  }

  & >>> .fa-icon {
    width: auto;
    height: 1em;
    max-width: 100%;
    max-height: 100%;
  }

  & >>> .accueil-space {
    padding-left: 6px;
  }

  & >>> .contact-space {
    padding-left: 2.5px;
  }

  & >>> .linkedin-space {
    padding-left: 4.5px;
  }

  & >>> .newArticle-space {
    padding-left: -1px;
  }

  & >>> .underline-link {
    border-bottom: 1px solid #fafafa;
    width: 100%;
    &:hover {
      border-bottom: 1px solid #093145;
      transition: 0.7s;
    }
  }
}
</style>