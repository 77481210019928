import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  //mode: "history",
  routes: [
    {
      path: "/",
      alias: "/Accueil",
      name: "Accueil",
      component: () => import('./components/Accueil')
    },
    {
      path: "/Newsletter",
      name: "Newsletter",
      component: () => import('./components/Newsletter')
    },
    {
      path: "/Contact",
      name: "Contact",
      component: () => import('./components/Contact')
    },
    {
      path: "/Admin",
      name: "Admin",
      component: () => import('./components/Admin')
    },
    {
      path: "/Nouvel-article",
      name: "Nouvel-article",
      component: () => import('./components/NouvelArticle')
    },
    {
      path: "/Modifier-article",
      name: "Modifier-article",
      component: () => import('./components/ModifierArticle')
    }
  ]
});